.header{
    background-color: whitesmoke;
    text-align: center;
    justify-content: center;

}

.header .inf{
    color: #2a3b8e;
    font-weight: bold;
}
.header .inf2{
    color: #ffffff;
    font-weight: bold;
    background-color: blue;
}

.header .inf h2{
    font-family: Sans, Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 32px;
}

.header .text-end{
    float: right;
    margin-top: 15px;
}
.header .text-start{
    margin-top: 5% !important;
}