
.btn-outline-success {
    /*color: #283b69;*/
    border-color: #283b69;
}
.btn-outline-success:hover{
    color: white;
    background-color: #283b69;
}
.search{
    /*float: right !important;*/
    /*!*margin-left: -200px;*!*/
    margin-right: 20px;
}
