/*.row{*/
    /*padding: 3% 0;*/
/*}*/

/*.member1 .card-text{*/
    /*overflow: hidden;*/
/*}*/
/*.member1 .card{*/
    /*height: 550px;*/
    /*margin:7% 3%;*/
    /*text-align: center;*/

/*}*/
/*.member1 .card-img{*/
    /*height: 60%;*/
    /*overflow: hidden;*/
/*}*/
/*.member1 .card:hover{*/
    /*overflow: inherit;*/

/*}*/
.rounded{
    height: 100%;
}