body {
    background-color: whitesmoke !important;
    font-family: Sans, Arial, Helvetica, sans-serif !important;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

fieldset {
    border: 1px solid #000!important;
    border-radius: 5px;
}

legend {
    margin-left: 10px;
    width: auto!important;
    float: none!important;
    padding: 10px !important;
}

.slayde {
    overflow: hidden;
}

.menuNavbar {
    background-color: #2a3b8e;
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;
    font-size: 13px;
}
.navbar-dark .navbar-nav button {
    color: white !important;
    font-size: 13px;
}
.navbar-dark .navbar-nav button .flag-icon {
    color: #000000 !important;
}

.members2 .card-header {
    background-color: white !important;
    border: none !important;
}

.footer {
    background-color:#2a3b8e;
    color: white;
    padding: 0 2%;
    bottom: 0;
}

/**************************/
/*@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');*/

.home,
.reports,
.products,
.team,
.reports {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.fHuvEA {
    background: #0d47a1 !important;
}

.gSYDCj {
    background: #0d47a1 !important;
}

/*******************MainJournal**************************************************/
.mainJournal {
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin-left: 7%;*/
}

.mainJournal .card {
    position: relative;
    width: 100%;
    height: auto;
    /*width: 318px;*/
    background: #fff;
    cursor: pointer;
    transform-style: preserve-3d;
    transition: 1s;
}

.mainJournal .card .imgBox {
    position: relative;
    width: 250px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.267);
    transition: 1s;
    z-index: 1;
    transform-origin: left;
    height: 350px;
}

.mainJournal .card .imgBox img {
    width: 100%;
    height: inherit;
    object-fit: cover;
}

.mainJournal .card .details {

    width: 100%;
    position: absolute;
    text-align: center;
    top:40%;
}

.mainJournal .card:hover {
    transform: rotate(0deg);
    box-shadow: inset 20px 0 50px rgba(0, 0, 0, 0.5);
}

.mainJournal .card:hover .imgBox {
    transform: rotateY(-100deg);
}

.mainJournal h3 {
    color: black;
    text-align: center;
}
/****************************************/

.selectJItem {
    width: auto!important;
    display: inline-table!important;
    margin: 0 5px;
}

.news-item__header {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: left;
}

.news-item__date {
    display: inline-block;
    padding: 5px 20px;
    font-size: 14px;
    color: #3a64a5;
    background: rgba(255,255,255,0.8);
    font-weight: 600;
}

.news-item__img {
    position: relative;
    text-align: center;
}
.news-item__img img {
    max-width: 450px;
    max-height: 230px;
}

.news-item__col2 {
    width: 50%;
    float: left;
    padding: 0 10px;
    margin-bottom: 20px;
}

.news-item__title {
    background: rgba(58, 100, 165, 0.8);
    color: #fff;
    padding: 10px 20px;
    height: 60px;
    overflow: hidden;
    margin: 9px;
}

.news-item__title h2 {
    font-size: 15px;
}


@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap !important;
        justify-content: flex-start !important;
        -ms-flex-flow: row nowrap !important;
        flex-flow: row nowrap !important;
        -ms-flex-pack: start !important;
    }
}
.localization{
    color: #aeb3b8!important;
}

#newsList{
    display: block;
    text-align: center;
    justify-content: center;
    margin: 0;
    padding:0 ;
}
/**************carusel*/
.carousel-caption{
    background: rgba(34, 41, 255, 0.5);
}

ul.dropdown-menu li button.dropdown-item, ul.dropdown-menu li button.dropdown-item:hover {
    background-color: #ffffff;
    color: #000000 !important;
}
ul.dropdown-menu li button.dropdown-item:disabled {
    background-color: #a8a8a8;
    color: #000000 !important;
}

.btn-primary {
    background-color: #2a3b8e !important;
}